import { reactive, readonly } from "vue";

export interface State {
  count: number;
  items: string[];
}

const state = reactive<State>({
  count: 0,
  items: [],
});

const incrementCount = () => {
  state.count++;
};

export default {
  state: readonly(state),
  incrementCount,
};

// export function useStore() {
//   const incrementCount = () => {
//     state.count++;
//   };

//   return {
//     state: readonly(state),
//     incrementCount,
//   };
// }
